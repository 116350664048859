<template>
  <div id="app">
    <Board />
  </div>
</template>

<script>
import Board from './components/board'

export default {
  name: 'App',
  components: {
    Board
  }
}
</script>

<style>
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: bold;
    src: url('assets/fonts/eot/IRANSansWeb_Bold.eot');
    src: url('assets/fonts/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('assets/fonts/woff2/IRANSansWeb_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('assets/fonts/woff/IRANSansWeb_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('assets/fonts/ttf/IRANSansWeb_Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 500;
    src: url('assets/fonts/eot/IRANSansWeb_Medium.eot');
    src: url('assets/fonts/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('assets/fonts/woff2/IRANSansWeb_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('assets/fonts/woff/IRANSansWeb_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('assets/fonts/ttf/IRANSansWeb_Medium.ttf') format('truetype');
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/eot/IRANSansWeb_Light.eot');
    src: url('assets/fonts/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('assets/fonts/woff2/IRANSansWeb_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('assets/fonts/woff/IRANSansWeb_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('assets/fonts/ttf/IRANSansWeb_Light.ttf') format('truetype');
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: 200;
    src: url('assets/fonts/eot/IRANSansWeb_UltraLight.eot');
    src: url('assets/fonts/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('assets/fonts/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('assets/fonts/woff/IRANSansWeb_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('assets/fonts/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
  }
  @font-face {
    font-family: IRANSans;
    font-style: normal;
    font-weight: normal;
    src: url('assets/fonts/eot/IRANSansWeb.eot');
    src: url('assets/fonts/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('assets/fonts/woff2/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('assets/fonts/woff/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('assets/fonts/ttf/IRANSansWeb.ttf') format('truetype');
  }

  body{
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab, #8e44ad, #2c3e50, #c0392b);
    background-size: 800% 800%;
    animation: gradient 120s linear infinite;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
#app {
  font-family: IRANSans, Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}



</style>
