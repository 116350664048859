import axios from 'axios'

export default {
  name: 'board',
  components: {},
  props: [],
  data () {
    return {
      list: []
    }
  },
  computed: {

  },
  mounted () {
    this.reloadInfo();
    setInterval(this.reloadInfo,5000);
  },
  methods: {
    reloadInfo: async function(){
      let result = await axios.post("https://api.faraex.ir/currency_get_list");
      let oldList = this.list;
      let newList = result.data;
      for(let i=0;i<oldList.length;i++){
        if(newList[i].price - oldList[i].price != 0){
          newList[i].change = newList[i].price - oldList[i].price;
        }
      }
      this.list = newList;
    }
  }
}


